* {
  margin: 0 0 0 0;
}

.App{
  width: 100%;
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
}

p {
  font-size: 30px;
}

html {
  scroll-snap-type: y mandatory
}
