.title-projects {
  width: 100%;
  height: 45vh;
  background: black;
  display: flex;
  position: relative;
  align-items: center;
}

.title-content {
  margin-left: 10%;
  font-family: 'Montserrat', sans-serif;
  font-size: clamp(70px,8vw,110px);
  background: -webkit-linear-gradient(0deg, #16bffd, #cb3066);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content-projects {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  background: black;
  /* padding: 5%; */
}

.project {
  overflow: hidden;
  color: black;
  width: clamp(200px, 80%, 350px);
  min-height: 40vh;
  border: 1px solid black;
  border-radius: 15px;
  margin: 2%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.project:hover{
  border: 1px solid white;
  cursor: pointer;
}

.project-title {
  color: white;
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  text-align: center;
  background-color: #052430;
  padding: 2% 0;
}

.project-video-container {
  overflow: hidden;
  height: 40vh;
}

.project-video {
  /* filter: grayscale(1); */
  filter: brightness(0.7);
}

.project-content {
  color: white;
  font-family: 'Oswald', sans-serif;
  font-size: clamp(15px, 2vw, 20px);
  font-weight: 400;
  text-align: center;
  /* background-color: #08394b; */
  background: linear-gradient(to bottom, #08394b, #0a475e);
  padding: 5%;
}

.project-expanded {
  width: 100vw;
  margin: 3% 0; 
}

.project-expanded-title {
  color: white;
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  text-align: center;
  background-color: #052430;
  padding: 2% 0;
}

.project-expanded-video {
  height: auto;
  float: left;
  width: 25%;
  min-width: 200px;
  padding: 3%;
  /* padding-left: 15%; */
  background-color: transparent;
}

.project-expanded-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  background: linear-gradient(to bottom, #08394b, #0a475e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.project-expanded-content-right {
}

.project-expanded-text {
  max-width: 600px;
  text-align: justify;
  padding: 0% 3% 5%;
  background-color: transparent;
  font-family: 'Oswald', sans-serif;
  font-size: clamp(15px, 2vw, 20px);
}

.project-expanded-subtitle {
  padding: 3%;
  padding-top: 5%;
  max-width: 600px;
  background-color: transparent;
  font-family: 'Oswald', sans-serif;
  /* padding-right: 20%; */
  font-weight: 700;
}

.prject-button-container {
  margin: 3% 3% 10%;
}

.project-button {
  user-select: true;
  font-family: 'Oswald', sans-serif;
  text-decoration: none;
  /* font-size: clamp(20px,3vw,30px); */
  color: white;
  font-weight: 500;
  border-radius: 10px;
  padding: 2% 4%;
  /* border: 2px solid black; */
  background: -webkit-linear-gradient(0deg, #1083ad, #af2a59);
}

.project-button:hover {
  animation-name: on-hover;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  border: 3px solid white;
  color: white;
}

@keyframes on-hover {
  from { border: 3px solid black;}
  to {border: 3px solid white;}
}

.project-image {
  align-items: center;
  /* min-width: 100%;
  height: 100%; */
  min-height: 100%;
  width: 100%;
}

.project-image-container {
  overflow: hidden;
  height: 40vh;
  display: flex;
  align-items: flex-end;
}

.project-expanded-image {
  width: 30%;
  min-width: 400px;
  padding: 3%;
}