.vis-robot {
  /* position: absolute;
  top: 0%;
  left: 0%; */
  width: 100%;
  height: 35vh;
}

.vis-robot *:focus {
  outline: none
}