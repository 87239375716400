.contacts {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: black;
}

.contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  font-family: 'Oswald', sans-serif;
  text-decoration: none;
}

.contact-icon {
  fill: white;
  margin: 2vh 4vh 4vh 20vw;
}

.contact-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;  
}

.contact-title {
  margin: 1vh;
}

.contact-text {
  margin: 1vh;
  font-weight: 100;
  /* font-size: 150%; */
  font-size: clamp(85%,2vw,150%);
}

.contact:hover {
  cursor: pointer;
}

.contact:hover .contact-icon {
  fill: #1db5ec;
  
}