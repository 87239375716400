body {
  color: white;
}

.title {
  width: 100%;
  height: 100vh;
  background: black;
}

.hello-world {
  position: absolute;
  bottom: 60%;
  left: 20%;
  line-height: 0.85;
  user-select: none;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: clamp(80px,8vw,110px);
  background: -webkit-linear-gradient(0deg, #16bffd, #cb3066);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
}

.body-text {
  margin: 5% 10%;
  color: white
}

.intro {
  /* position: relative;
  top: 0%;
  left: 0; */
  height: 65vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.intro-title {
  font-size: clamp(50px,8vw,110px);
  font-family: 'Montserrat', sans-serif;
  color: #16bffd;
  user-select: none;
}

.intro-content {
  font-family: 'Oswald', sans-serif;
  font-size: clamp(20px,3vw,30px);
  font-weight: 300;
  margin-top: 5%;
  width: clamp(300px, 50%, 1000px);
  text-align: justify;
}

.EOF{
  font-family: 'Oswald', sans-serif;
  font-weight: 100;
  font-size: clamp(20px,2vw,25px);
  color: rgb(156, 156, 156);
  background: black;
  text-align: center;
  padding: 20% 0 10%;
}

.cv {
  height: 50vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cv-title {
  font-size: clamp(50px,8vw,110px);
  font-family: 'Montserrat', sans-serif;
  color: #16bffd;
  user-select: none;
  margin-top: 5%;
}

.cv-content {
  font-family: 'Oswald', sans-serif;
  font-size: clamp(20px,3vw,30px);
  font-weight: 300;
  margin-bottom: 5%;
  width: clamp(300px, 50%, 1000px);
  margin-top: 5%;
  text-align: center;
}

.cv-button {
  user-select: true;
  text-decoration: none;
  font-family: 'Oswald', sans-serif;
  font-size: clamp(20px,3vw,30px);
  color: white;
  font-weight: 500;
  border-radius: 10px;
  padding: 2% 4%;
  border: 2px solid black;
  background: -webkit-linear-gradient(0deg, #1083ad, #af2a59);
}

.cv-button:hover {
  animation-name: on-hover;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  border: 3px solid white;
  color: white;
}

@keyframes on-hover {
  from { border: 3px solid black;}
  to {border: 3px solid white;}
}