.menu {
  position: fixed;
  top: 1%;
  right: 1%;
  z-index: 1;
  background-color: transparent;
}

.menu-hamburger {
  border: 3px solid black;
  border-radius: 50%;
  background-color: black;
}

.menu-hamburger *:focus {
  outline: none
}

.navBar {
  padding: 1% 0 1%;
  width: 30%;
  min-width: 300px;
  position: fixed;
  top: 0;
  right: 2%;
  display: flex;
  justify-content: space-around;
  z-index: 999;
}
.nav-title {
  vertical-align: center;
  width: 10%;
  color: white;
  text-decoration: none;
}

.nav-title:hover {
  vertical-align: center;
  width: 10%;
}

.nav-title-active {
  vertical-align: center;
  font-weight: bold;
  width: 10%;
}

.full-screen-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: -100%;
  right: 0;
  z-index: -1;
  background: rgb(34, 34, 34);
  opacity: 0.99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation-name: move-up;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  font-family: 'Montserrat', sans-serif;
}

.full-screen-menu-active {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background: rgb(34, 34, 34);
  opacity: 0.99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation-name: move-down;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  font-family: 'Montserrat', sans-serif;
}

@keyframes move-down {
  from { top: -100% }
  to { top: 0% }
}

@keyframes move-up {
  from { top: 0% }
  to { top: -100% }
}

.nav-title-full-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2%;
  color: rgb(114, 114, 114);
  text-decoration: none;
  background: transparent;
  font-size: 80px;
}

.nav-title-full-screen:hover {
  color: white;
}

.nav-title-active-full-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-weight: bold; */
  background: transparent;
}