/* bilbo-swash-caps-regular - latin */
@font-face {
  font-family: 'Bilbo Swash Caps';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/bilbo-swash-caps-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Bilbo Swash Caps Regular'), local('BilboSwashCaps-Regular'),
       url('../fonts/bilbo-swash-caps-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/bilbo-swash-caps-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/bilbo-swash-caps-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/bilbo-swash-caps-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/bilbo-swash-caps-v12-latin-regular.svg#BilboSwashCaps') format('svg'); /* Legacy iOS */
}

.logo {
  text-align: center;
  position: fixed;
  left: 10px;
  top: 2%;
  width: 160px;
  text-align: center;
  font-family: 'Bilbo Swash Caps', cursive;
  user-select: none;
  /* background-color: black; */
  z-index:9;
}

.logo-link {
  color: white;
  text-decoration: none;
}

.logo-link-active {
  color: white;
  text-decoration: none;
}

.menu-bar {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  width: 160px;
  border-radius: 15px;
  overflow: hidden;
  height: 6%;
  z-index: 8;
  top: 1%;
  left: 5px;
  backdrop-filter: blur(10px);
}